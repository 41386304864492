import * as React from 'react'
import styled from 'styled-components'

import { SizedSvg } from '../SizedSvg'

export type Collection =
  | 'coffee'
  | 'colored'
  | 'crowdfunding'
  | 'currencies'
  | 'fill'
  | 'paymentMethods'
  | 'stroke'
  | 'thirdParty'
  | 'twoToned'

export type IconProps = React.SVGAttributes<SVGElement> & {
  children: React.ReactNode
  className?: string
  name: string
  onClick?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void
  /** @default 32 */
  size?: number | string
  title?: string
  /** @default '0 0 32 32' */
  viewBox?: string
}

/** Base SVG Icon Component */
function IconComponent({
  children,
  className,
  name,
  onClick,
  size = 32,
  title,
  viewBox = '0 0 32 32',
  clipRule = 'evenodd',
  fillRule = 'evenodd',
  ...svgProps
}: IconProps): React.ReactElement<IconProps> {
  return (
    <SizedSvg
      aria-labelledby={title && 'title'}
      className={className}
      clipRule={clipRule}
      fillRule={fillRule}
      height={size}
      id={`icon-${name}`}
      onClick={onClick}
      preserveAspectRatio="xMidYMid meet"
      $size={size}
      viewBox={viewBox}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      {title && <title id="title">{title}</title>}
      {children}
    </SizedSvg>
  )
}

export const Icon = styled(IconComponent)<IconProps>``
Icon.displayName = 'glyphs.Icon'
