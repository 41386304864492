import { Project } from '@owl-nest/api-client/latest'

import { SafePush } from '../safePush'
import { getAnalyticsTags } from '../analyticsTags'

import { EcommerceEvent, EcommerceEventType, EcommerceTracking, PartialEcommerceEvent } from './types'

const VIEW_ITEM_BATCH_TIMEOUT = 500

export function tracking(safePush: SafePush, project?: Project<'analytics'>): EcommerceTracking {
  const viewItemBatch: PartialEcommerceEvent<'view_item'>[] = []
  let pushViewItemBatchTimeout: ReturnType<typeof setTimeout> | undefined = undefined
  const analyticsTags = getAnalyticsTags(project)

  function track<TYPE extends EcommerceEventType>(type: TYPE, event: PartialEcommerceEvent<TYPE>): void {
    if (type === 'view_item') {
      viewItemBatch.push(event)

      if (pushViewItemBatchTimeout === undefined) {
        pushViewItemBatchTimeout = setTimeout(() => {
          safePush<EcommerceEvent<'view_item'>>({
            event: type,
            fired_from: 'ulule',
            ululeProjectId: project?.id,
            ecommerce: viewItemBatch.reduce(
              (aggregatedEvent, event) => {
                aggregatedEvent.items.push(...event.items)
                return aggregatedEvent
              },
              { items: [] },
            ),
            ...(analyticsTags !== undefined && { poTagIDs: analyticsTags }),
          })
          viewItemBatch.length = 0
          pushViewItemBatchTimeout = undefined
        }, VIEW_ITEM_BATCH_TIMEOUT)
      }
    } else {
      safePush<EcommerceEvent<TYPE>>({
        event: type,
        fired_from: 'ulule',
        ululeProjectId: project?.id,
        ecommerce: event,
        ...(analyticsTags !== undefined && { poTagIDs: analyticsTags }),
      })
    }
  }

  return { track }
}
