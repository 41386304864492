import styled, { css } from 'styled-components'

import * as colors from '../../constants/colors'
import * as effects from '../../constants/neue/effects'
import * as fonts from '../../constants/fonts'
import * as glyphs from '../../icons/glyphs'
import * as heading from '..//heading'
import * as copy from '../copy'

import { Tag } from '../tag'

type CardWrapperProps = {
  fullWidth?: boolean
  bordered?: boolean
  borderColor?: string
}

export const CardWrapper = styled.div<CardWrapperProps>`
  background-color: ${colors.PRIMARY_GREY_000};
  border-radius: 2px;
  box-shadow: ${effects.SHADOW_2};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  position: relative;
  transition: box-shadow 300ms ease-in-out;

  ${({ bordered, borderColor, theme }) => {
    if (bordered) {
      if (borderColor) {
        return css`
          border: 2px solid ${borderColor};
        `
      } else {
        return css`
          border: 2px solid ${theme?.colors?.primary ?? colors.PRIMARY_BLUE_500};
        `
      }
    }
  }}

  ${({ fullWidth }) => {
    if (!fullWidth) {
      return css`
        max-width: 288px;
      `
    }
  }}

  & > :last-child {
    margin-top: auto;
  }

  :hover {
    box-shadow: ${effects.SHADOW_5};
  }

  ${Tag} {
    font-family: ${fonts.PRIMARY_FONT};
    font-size: 14px; // HACK: Irregular font manipulation
    line-height: 22px; // HACK: Irregular font manipulation
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 2px 6px;

    ${glyphs.Icon} {
      top: 1px !important;
    }
  }
`

export const CardTitle = styled(heading.XS)`
  position: relative;
  text-align: center;
`

export const CardImage = styled.img`
  border-radius: 4px;
  object-fit: cover;
  width: 100%;
`

export const TaxDeduction = styled(copy.S)`
  align-items: center;
  align-self: center;
  color: ${colors.PRIMARY_SAND_700};
  display: flex;
  gap: 5px;

  ${glyphs.Icon} {
    color: ${colors.PRIMARY_SAND_500};
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;

  li,
  p,
  strong {
    font-size: 14px;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const CardDescription = styled(copy.S)`
  p,
  ul,
  ol {
    margin: 0;
    white-space: pre-wrap;
  }
`

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CardSubsRemaining = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: end;
  margin-left: auto;

  & > :nth-child(2) {
    color: ${({ theme }) => `${theme?.colors?.primary ?? colors.PRIMARY_BLUE_500}`};
  }
`

export const Price = styled(heading.XXXS)`
  color: ${colors.PRIMARY_BLUE_500};
  text-align: center;
`

export const Tags = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const MemberTag = styled(Tag)`
  align-items: center;
  display: flex;
  gap: 8px;
  left: 50%;
  padding-inline: 7px;
  position: absolute;
  top: -15px;
  transform: translateX(-50%);

  ${glyphs.Icon} {
    margin-right: 0 !important;
  }
`

export const QuantitySelected = styled(copy.S)`
  align-items: center;
  color: ${colors.PRIMARY_SAND_700};
  display: flex;
  gap: 6px;
  margin: 0 auto;
`

export const CurrencyConversion = styled.div`
  ${copy._smallStyle};
  color: ${colors.PRIMARY_SAND_700};
`
