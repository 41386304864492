import * as React from 'react'

import { Project } from '@owl-nest/api-client/latest'
import * as logger from '@owl-nest/logger'

import { getAnalyticsTags } from './analyticsTags'
import { trackingContext, TrackingContext } from './context'
import { safePusher } from './safePush'
import { AnalyticsTags, SITE } from './types'
import * as UFE from './utils/UFE'

import * as api from '@owl-nest/api-client/latest'

import { tracking } from './base/tracking'
import { tracking as brandTracking } from './brand/tracking'
import { tracking as projectTracking } from './project/tracking'
import { tracking as socialTracking } from './social/tracking'
import { tracking as transactionTracking } from './transaction/tracking'
import { tracking as ecommerceTracking } from './ecommerce/tracking'

declare global {
  interface Window {
    dataLayer?: any[]
    google_tag_manager?: any
    ga?: {
      loaded?: boolean
    }
    UFE: any
  }
}

type TrackingProviderProps = {
  children: React.ReactNode
  getUserId?: () => number | undefined
  gtmContainerId: string
  pageCategory?: string
  project?: Project<'analytics'>
  siteCategory?: SITE
  userLocale?: api.Lang
}

export function TrackingProvider({
  children,
  getUserId = () => undefined,
  gtmContainerId,
  pageCategory,
  project,
  siteCategory,
  userLocale,
}: TrackingProviderProps): React.ReactElement<TrackingProviderProps> {
  if (!pageCategory) {
    logger.warn('[shadow] No pageCategory provided')
  }

  userLocale = userLocale || UFE.USER_LOCALE

  // If GTM is not initialized, inject the script and push the first event
  // along with useful information (custom analytics tags, project ID, etc.) when available.
  // 2021-04-29 We need the optimize variable to launch GTM when Optimize was previously loaded
  // @ts-ignore
  if (typeof window !== 'undefined' && (window.dataLayer === undefined || window?.UFE?.optimize === true)) {
    // @ts-ignore
    window.UFE = window.UFE || {}
    window.UFE.optimize = false
    const analyticsTags = getAnalyticsTags(project)
    const projectCategory = project?.main_tag?.id
    const ululeProjectId = project?.id
    const ululeUserId = getUserId()

    initialize(gtmContainerId, userLocale, {
      analyticsTags,
      pageCategory,
      projectCategory,
      siteCategory,
      ululeProjectId,
      ululeUserId,
    })
  }

  const value: TrackingContext = {
    ...tracking(safePusher(gtmContainerId)),
    brand: brandTracking(safePusher(gtmContainerId), project),
    social: socialTracking(safePusher(gtmContainerId), project),
    project: projectTracking(safePusher(gtmContainerId), project, getUserId),
    transaction: transactionTracking(safePusher(gtmContainerId), project),
    ecommerce: ecommerceTracking(safePusher(gtmContainerId), project),
  }

  return <trackingContext.Provider value={value}>{children}</trackingContext.Provider>
}

type InitOptions = {
  analyticsTags: AnalyticsTags | undefined
  pageCategory: string | undefined
  projectCategory: number | undefined
  siteCategory: SITE | undefined
  ululeProjectId: number | undefined
  ululeUserId: number | undefined
}

function initialize(gtmContainerId: string, userLocale: api.Lang, options: InitOptions): void {
  if (!gtmContainerId) return

  const { analyticsTags, pageCategory, projectCategory, siteCategory, ululeProjectId, ululeUserId } = options

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
    userLocale: userLocale,
    ...(analyticsTags !== undefined && { poTagIDs: analyticsTags }),
    ...(pageCategory !== undefined && { pageCategory }),
    ...(projectCategory !== undefined && { projectCategory: String(projectCategory) }),
    ...(siteCategory !== undefined && { siteCategory }),
    ...(ululeProjectId !== undefined && { ululeProjectId: String(ululeProjectId) }),
    ...(ululeUserId !== undefined && { ululeUserId: String(ululeUserId) }),
  })

  const firstScript = document.getElementsByTagName('script')[0]
  const newScript = document.createElement('script')
  newScript.async = true
  newScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmContainerId}`

  if (firstScript.parentNode !== null) {
    firstScript.parentNode.insertBefore(newScript, firstScript)
  }
}
